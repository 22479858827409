import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { LoadingIndicatorStoreItems } from "./loading-indicator/LoadingIndicatorStoreItems";
import { LoadingType } from "./types/LoadingTypes";
import { ItemList } from "./store-items/ItemList"
import { Category, CategoryProps } from "./Category";
import { EnvironmentContext } from "./EnvironmentContext";
import { useAuth } from "react-oidc-context";
import { useStoreBackend } from "./hooks/useStoreBackend";
import { GroupedStoreItem } from "./objects/GroupedStoreItem";

export const HomeCategories = () => {

    const [storeData, setStoreData] = useState<GroupedStoreItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingFailed, setLoadingFailed] = useState(false);
    const { environment } = useContext(EnvironmentContext);
    const { t } = useTranslation();
    const sb = useStoreBackend();

    useEffect(() => {
        setLoadingFailed(false);
        sb.getStoreItems({ groupBy: "ManifestType", groupByLimit: 6 })
            .then(data => {
                setStoreData(data as GroupedStoreItem[]);
            })
            .catch(() => {
                setLoadingFailed(true);
            }).finally(() => {
                setLoading(false);
            });
    }, [environment]);

    /**
     * Return store items
     * @param identifier 
     * @returns 
     */
    const getStoreItems = (index: number, identifier: string) => {
        // Get list of store items
        const storeCategory = storeData.filter(i => i.identifier === identifier);
        if (storeCategory.length === 1) {
            const storeType = storeCategory[0];
            return <ItemList data={storeType} prevPage="/" groupKey={index} key={`l-${index}`} home={true} />;
        }

        return <div>{t("NO_VALID")}</div>
    };

    /**
     * Retrieves content by given store item identifier
     * @param identifier identifier of the store item groupby
     */
    const getContent = (idx: number, identifier: string) => {
        if (loading) {
            return <LoadingIndicatorStoreItems key={idx} indicator={LoadingType.LOADING} />
        } else if (loadingFailed) {
            return <LoadingIndicatorStoreItems key={idx} indicator={LoadingType.ERROR} text="Er is iets mis gegaan met het inladen van de gegevens" />
        }
        else if (storeData.status) {
            return <LoadingIndicatorStoreItems key={idx} indicator={LoadingType.ERROR} text={`Er is iets mis gegaan met het inladen van de gegevens: ${storeData.detail}`} />
        }
        else {
            return getStoreItems(idx, identifier);
        }
    };

    /**
     * Define the main categories as shown on the homepage
     */
    const categories: CategoryProps[] = [
        {
            title: t("APPS_TITLE"),
            description: t("APPS_DESCRIPTION"),
            moreText: t("APPS_WATCH"),
            moreTo: "/apps",
            contentIdentifier: "App",
            className: "style-apps"
        },
        {
            title: t("EXTENSIONS_TITLE"),
            description: t("EXTENSIONS_DESCRIPTION"),
            moreText: t("EXTENSIONS_WATCH"),
            moreTo: "/extensions",
            contentIdentifier: "Extension",
            content: getContent(1, "Extension"),
            className: "style-extensions"
        },
        {
            title: t("BLOCKS_TITLE"),
            description: t("BLOCKS_DESCRIPTION"),
            moreText: t("BLOCKS_WATCH"),
            moreTo: "/blocks",
            contentIdentifier: "Block",
            className: "style-blocks"
        }
    ];

    return <>
        {categories.map((category, index) => <Category key={index} {...category} content={getContent(index, category.contentIdentifier)} />)}
    </>
}